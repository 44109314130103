<template>
  <div>
    <h1 class="text-xl font-semibold">Raporty finansowe, zarządzanie wypłatami</h1>
    <router-link
      v-if="$route.name !== 'salaries'"
      :to="{ name: 'salaries'}"
      class="inline-block px-3 py-1 mr-1 font-semibold bg-blue-500 hover:bg-blue-700 text-blue-100 hover:text-white rounded-sm hover:no-underline"
    >Pracownicy</router-link>
    <span
      v-else
      class="inline-block px-3 py-1 mr-1 font-semibold bg-blue-400 text-yellow-300 rounded-sm"
    >Pracownicy</span>
    <router-link
      v-if="$route.name !== 'orderIncomeReport'"
      :to="{ name: 'orderIncomeReport'}"
      class="inline-block px-3 py-1 font-semibold bg-blue-500 hover:bg-blue-700 text-blue-100 hover:text-white rounded-sm hover:no-underline"
    >Zleceniodawcy</router-link>
    <span
      v-else
      class="inline-block px-3 py-1 font-semibold bg-blue-400 text-yellow-300 rounded-sm"
    >Zleceniodawcy</span>

    <hr class="my-1">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Settlements',
};
</script>
